import React from 'react';

import InnerActivityRing from './InnerActivityRing';
import { ActivityRing, ActivityRingContainerOptions } from './types';
import CenteredSVG from '../../CenteredSVG';

const defaultOptions: ActivityRingContainerOptions = {
  containerHeight: '100%',
  containerWidth: '100%',
  paddingBetweenRings: 0.75,
  initialRadius: 40,
  ringWidth: 15,
  animationDurationMillis: 1000,
  animationTimingFunction: 'ease-in-out',
  backgroundOpacity: 0.4,
};

export default function ActivityRings(props: Props) {
  const options = Object.assign(
    {},
    defaultOptions,
    props.options
  ) as Required<ActivityRingContainerOptions>;
  const viewBoxSize = 100 + props.rings.length * (21.5 + options.paddingBetweenRings);

  // Please don't ask about the numbers on the CenteredSVG object. They work.
  return (
    <svg
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      height={options.containerHeight}
      width={options.containerWidth}
    >
      <g>
        {props.rings.map((ring, index) => (
          <InnerActivityRing key={index} index={index} ring={ring} options={options} />
        ))}
        <CenteredSVG
          dimensions={{ width: 45, height: 45 }}
          initialYPositionCalcString={`48%`}
          initialXPositionCalcString={`48%`}
        >
          {props.options?.ringsCenterComponent}
        </CenteredSVG>
      </g>
    </svg>
  );
}

type Props = {
  rings: ActivityRing[];
  options?: ActivityRingContainerOptions;
};
