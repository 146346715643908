import { Box, Card, CardActionArea, CardContent, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'common/@types/dayjs-custom';
import { ReactElement } from 'react';
import ConditionalWrapper from '../../../ConditionalWrapper';
import DailyActivityRings from '../../DailyActivityRing/DailyActivityRings';
import { metricDisplayConfig } from '../../config';
import { LearningSessionDisplay } from '../../types';
import Medal from './medal/Medal';
import { LearningSessionFormatterProps } from './types';

export const getLearningSessionDisplay = (
  learningSession: any,
  theme: any
): LearningSessionDisplay[] => {
  const round = (num: number) => Math.round(num * 100) / 100;
  const isNullish = (val: number) => val === null || val === undefined || isNaN(val);
  const orNull = (input: number) => (!isNullish(input) ? input.toString() : 'NA');

  const accuracyTarget = 80;

  const minutesValueLabel = orNull(learningSession.minutes_working);
  const accuracyValueLabel = !isNullish(learningSession.perc_questions_correct)
    ? round(learningSession.perc_questions_correct * 100) + '%'
    : 'NA';
  const qcphValueLabel = orNull(learningSession.correct_questions_per_hour);

  const minutes =
    learningSession.subject === 'Writing' &&
    learningSession.app.toLowerCase() === 'alphawrite' &&
    dayjs(learningSession.date) >= dayjs('2024-02-05')
      ? 10
      : 25; // Eng-Maint #6917 Reduce required minutes to 10 for AlphaWrite in Writing

  const config: LearningSessionDisplay[] = [
    {
      name: 'Minutes',
      key: 'minutes',
      actual: learningSession.minutes_working,
      target: minutes,
      icon: metricDisplayConfig.minutes.icon,
      enabled: true,
      ring: {
        color: '#FA3581',
        valueLabel: minutesValueLabel,
        tooltip: {
          actualMetricValue: minutesValueLabel,
          targetMetricValue: minutes.toString(),
          subtitleLabel: `${learningSession.correct_questions_per_hour} correct questions per hour`,
          subtitleBackgroundColor: '#FFE4EF',
        },
      },
    },
    {
      name: 'Accuracy',
      key: 'accuracy',
      actual: learningSession.perc_questions_correct * 100,
      target: accuracyTarget,
      icon: metricDisplayConfig.accuracy.icon,
      enabled: true,
      ring: {
        color: '#58AFFF',
        valueLabel: accuracyValueLabel,
        tooltip: {
          actualMetricValue: accuracyValueLabel,
          targetMetricValue: accuracyTarget.toString() + '%',
          subtitleLabel: `${learningSession.correct} correct / ${learningSession.total} total`,
          subtitleBackgroundColor: '#E0EFFF',
        },
      },
    },
    {
      name: 'Correct Questions Per Hour',
      key: 'qcph',
      icon: metricDisplayConfig.qcph.icon,
      actual: learningSession.correct_questions_per_hour,
      target: learningSession.learning_2x_threshold,
      enabled: learningSession.should_qcph_count,
      ring: {
        color: '#81EE4D',
        valueLabel: qcphValueLabel,
      },
    },
  ];

  let masteryActual;
  let masteryTarget;
  let totalMastery;

  if (
    typeof learningSession.mastered_levels === 'string' &&
    learningSession.mastered_levels?.includes(':')
  ) {
    // take the values for the app with most progress
    const masteredLevels = (learningSession.mastered_levels || '')
      .split(',')
      .map((level: string) => {
        const [app, value] = level.split(':');
        return { app, value: Number(value) };
      });
    const targetMasterUnits = (learningSession.target_master_units || '')
      .split(',')
      .map((unit: string) => {
        const [app, value] = unit.split(':');
        return { app, value: Number(value) };
      });
    // AI-GEN START - Cursor and GPT4
    // Start of Selection
    totalMastery = masteredLevels.reduce(
      (sum: number, level: { value: number }) => sum + level.value,
      0
    );
    // AI-GEN END
    const progress = masteredLevels.map((level: { app: string; value: number }, index: number) => ({
      app: level.app,
      progress: level.value / targetMasterUnits[index]?.value,
    }));
    progress.sort(
      (a: { app: string; progress: number }, b: { app: string; progress: number }) =>
        b.progress - a.progress
    );
    const closestTo100 = progress[0];
    masteryActual = masteredLevels.find(
      (level: { app: string; value: number }) => level.app === closestTo100.app
    )?.value;
    masteryTarget = targetMasterUnits.find(
      (unit: { app: string; value: number }) => unit.app === closestTo100.app
    )?.value;
  } else {
    masteryActual = learningSession.is_above_mastery_threshold === 'true' ? 1 : 0;
    totalMastery = masteryActual;
    masteryTarget = 1;
  }
  const masteryConfig: LearningSessionDisplay = {
    name: 'Mastery',
    key: 'mastery',
    actual: masteryActual,
    target: masteryTarget,
    icon: metricDisplayConfig.mastery.icon,
    enabled: true,
    ring: {
      color: theme.palette.chart.violet[2],
      valueLabel: totalMastery,
      tooltip: learningSession.mastered_levels === 'NA' || learningSession.target_master_units === 'NA'
      ? undefined
      : {
          subtitleLabel: (
            <>
              <Box sx={{ paddingY: '6px' }} key="mastered_levels">
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', display: 'block' }}>
                  Mastered levels:
                </Typography>

                {(learningSession.mastered_levels || '').split(',').map((level: string) => (
                  <Typography key={level} sx={{ fontSize: '12px', display: 'block' }}>
                    {level}
                  </Typography>
                ))}
              </Box>
              <Box sx={{ paddingY: '6px' }} key="target_master_units">
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', display: 'block' }}>
                  Target mastered levels:
                </Typography>

                {(learningSession.target_master_units || '').split(',').map((level: string) => (
                  <Typography key={level} sx={{ fontSize: '12px', display: 'block' }}>
                    {level}
                  </Typography>
                ))}
              </Box>
            </>
          ),
        subtitleBackgroundColor: '#FFE4EF',
      },
    },
  };
  config.push(masteryConfig);
  return config;
};

const RadialGaugeLearningSessionFormatter = ({
  learningSession,
  onClick,
  isLoading,
  sx,
  children,
}: LearningSessionFormatterProps) => {
  const theme = useTheme();
  if (isLoading) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  } else if (!isLoading && learningSession) {
    const metrics = getLearningSessionDisplay(learningSession, theme);
    const is2hrSession = learningSession.is_2hr_session === 'true';
    const has2hrOverride = learningSession.has_2hr_override === 'true';
    const overrideExplanation = learningSession.override_explanation;
    const hasAntipattern = learningSession.has_antipattern;
    const is2hrSessionColor = is2hrSession ? '#7ED86F' : '#E08183';
    const isGoalMet = false;
    const streakMedalType = 'gold';
    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0px',
          border: '2px solid ',
          borderColor: is2hrSessionColor,
          boxShadow: '0px 16px 32px -4px rgba(145, 158, 171, 0.16)',
          maxHeight: '370px',
          maxWidth: '250px',
          ...sx,
        }}
      >
        {children}
        <ConditionalWrapper
          condition={hasAntipattern === true}
          wrapper={(children: ReactElement) => (
            <CardActionArea sx={{ height: '100%' }} onClick={onClick}>
              {children}
            </CardActionArea>
          )}
        >
          <CardContent
            sx={{
              padding: '0px !important',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Box width={'100%'} height={'100%'} sx={{ paddingX: '10px !important' }}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <DailyActivityRings
                  key={
                    learningSession.campus_and_student_id +
                    learningSession.date +
                    learningSession.subject
                  }
                  metrics={metrics}
                  hasAntipattern={learningSession.has_antipattern === true}
                />
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  sx={{ marginY: '6px', minHeight: '40px' }}
                >
                  <Typography
                    fontSize={'14px'}
                    color={theme.palette.text.secondary}
                    fontWeight={500}
                    sx={{ whiteSpace: 'normal', overflowWrap: 'break-word', textAlign: 'center' }}
                  >
                    {learningSession.app}
                  </Typography>
                  <Typography
                    fontSize={'12px'}
                    color={theme.palette.text.secondary}
                    fontWeight={500}
                    sx={{ whiteSpace: 'normal', overflowWrap: 'break-word', textAlign: 'center' }}
                  >
                    {learningSession.course || ''}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'stretch'}
              sx={{
                position: 'absolute',
                bottom: '0px',
                width: '100%',
                textAlign: 'center',
              }}
            >
              <Box
                sx={{
                  backgroundColor: is2hrSessionColor,
                  height: '30px',
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'center',
                }}
              >
                <Typography sx={{ color: 'white', fontWeight: 700, marginTop: '4px' }}>
                  {is2hrSession ? `2h Session 🔥` : 'Non-2h Session'}
                  {isGoalMet && (
                    <Tooltip
                      title={
                        <>
                          <span>Custom Goal met!</span>
                          <br />
                          <span>Catch Up: 2 Grade Levels</span>
                          <br />
                          <span>2 Lessons per Day</span>
                        </>
                      }
                    >
                      <span style={{ cursor: 'pointer', color: theme.palette.error.dark }}>
                        {' '}
                        🚀{' '}
                      </span>
                    </Tooltip>
                  )}
                  {has2hrOverride && (
                    <Tooltip title={overrideExplanation}>
                      <span style={{ cursor: 'pointer', color: theme.palette.error.dark }}>
                        {' '}
                        ✍{' '}
                      </span>
                    </Tooltip>
                  )}
                </Typography>

                {learningSession.twohr_streak_length > 1 && (
                  <Tooltip title="2Hr Session Streak">
                    <span style={{ paddingLeft: '2px', display: 'inline-block' }}>
                      <Medal
                        medalType={streakMedalType}
                        number={learningSession.twohr_streak_length}
                      />
                    </span>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </CardContent>
        </ConditionalWrapper>
      </Card>
    );
  } else {
    return (
      <div style={{ textAlign: 'center' }}>
        <h1>No data</h1>
      </div>
    );
  }
};

export default RadialGaugeLearningSessionFormatter;
