import { Student } from '../utils/amplify/getStudents';
import { AuthUser, LevelDisplayName, Role } from '../@types/auth';
import { trpc } from '../utils/trpcAmplify';
import useAuth from './useAuth';
import useSiteConfig from './useSiteConfig'; // AI-GEN - Cursor - GPT4
import { SiteConfig } from 'common/@types/siteConfig';

const isMultilevelGuide = (inputRole: Role, inputUser: AuthUser) =>
  inputRole === Role.GUIDE &&
  inputUser?.alphaAuthAttributes?.levels.find((l) => l.displayName === LevelDisplayName.Multilevel);

const getStudentIds = (user: AuthUser, role: Role): string[] => {
  if (role == Role.PARENT) {
    return user?.alphaAuthAttributes?.childStudentIds || [];
  } else if (role == Role.STUDENT) {
    const studentId = user?.alphaAuthAttributes?.studentId;
    return studentId ? [studentId] : [];
  }
  return [];
};
const getAlphaLevel = (user: AuthUser, role: Role): number[] | null => {
  const lookupString = user?.alphaAuthAttributes?.levels[0]?.lookupString;
  if ([Role.ADMIN, Role.GUIDE].includes(role)) {
    // Admins, BTX guides, and multilevel guides can see all levels
    if (
      [Role.ADMIN].includes(role) ||
      user?.alphaAuthAttributes?.campusId == '3' ||
      isMultilevelGuide(role as Role, user)
    ) {
      // Previously [0, 0.5, 1, 2, 3, 4], but this excluded 4.1/4.2/etc; empty array effectively wildcard
      return [];
    } else {
      // handling LL & linc guides
      if (lookupString === '<1') {
        return [0, 0.5];
      }
      // handling L4 guides
      if (lookupString === '>=4') {
        return [4]; // This will be converted into 4.1, 4.2, 4.3, 4.4 in the backend
      }
      // base case
      return [Number(lookupString)] || -1;
    }
  }
  return null;
};

const getCampusId = (user: AuthUser, role: Role, siteConfig?: SiteConfig): string[] | undefined => {
  // AI-GEN - Cursor - GPT4

  if ([Role.ADMIN, Role.GUIDE].includes(role)) {
    if ([Role.ADMIN].includes(role) || isMultilevelGuide(role as Role, user)) {
      return siteConfig?.campuses ?? []; // AI-GEN - Cursor - GPT4
    }
  }
  return user?.alphaAuthAttributes?.campusId ? [user.alphaAuthAttributes.campusId] : undefined; // AI-GEN - Cursor - GPT4
};

export default function useStudentList(
  overrideCampusFilter: boolean = false
): Student[] | undefined {
  // AI-GEN - Cursor - GPT4
  const { user } = useAuth();
  const { data: siteConfig, fetchStatus: siteConfigFetchStatus } = useSiteConfig(); // AI-GEN - Cursor - GPT4
  const role = user?.alphaAuthAttributes?.role;

  const studentIds = getStudentIds(user, role as Role);
  const alphaLevel = getAlphaLevel(user, role as Role);
  let campusIds: string[] | undefined = []; // Initialize campusIds as an empty array // AI-GEN - Cursor - GPT4
  if (!overrideCampusFilter) {
    // Conditionally populate campusIds based on applyCampusFilter // AI-GEN - Cursor - GPT4
    campusIds = getCampusId(user, role as Role, siteConfig ?? undefined); // AI-GEN - Cursor - GPT4
  } // AI-GEN - Cursor - GPT4

  const response = trpc.masterRoster.getStudents.useQuery(
    {
      alphaLevel: alphaLevel || [],
      campusIds: campusIds || [],
      studentIds: studentIds,
    },
    {
      enabled:
        siteConfigFetchStatus === 'fetched' &&
        ((!!alphaLevel && alphaLevel.length > 0) ||
          !!campusIds ||
          (!!studentIds && studentIds.length > 0)),
    }
  );
  return response.data;
}
