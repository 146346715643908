// @mui
import { ComputedLearningSessionMetric, LearningSessionDisplay } from '../types';
import ActivityRings from '../../chart/ActivityRings/ActivityRings';
import React from 'react';
import Iconify from '../../Iconify';
import SVGText from '../../SVGText';
import RingTooltipContent from './RingTooltipContent';

export default function DailyActivityRings({ metrics, hasAntipattern }: Props) {
  const computedMetrics: ComputedLearningSessionMetric[] = metrics.map((metric, index) => {
    const { actual, target } = metric;
    const percent = actual === 0 || target === 0 ? 0 : actual / target;
    return { metric, percent };
  });

  const generateRingData = (metric: ComputedLearningSessionMetric) => {
    const icon = <Iconify icon={metric.metric.icon} />;
    return {
      filledPercentage: metric.percent,
      color: metric.metric.ring.color,
      tooltipInnerElement: (
        <RingTooltipContent
          icon={icon}
          label={metric.metric.name}
          targetValue={metric.metric.ring.tooltip?.targetMetricValue}
          actualValue={metric.metric.ring.tooltip?.actualMetricValue}
          subtitleLabel={metric.metric.ring.tooltip?.subtitleLabel}
          subtitleBackgroundColor={metric.metric.ring.tooltip?.subtitleBackgroundColor}
        />
      ),
      ringTopComponent: icon,
      ringBottomComponent: (
        <SVGText
          text={metric.metric.ring.valueLabel}
          x={0}
          y={10}
          fontSize={10}
          fontWeight={700}
          fill={'black'}
        />
      ),
    };
  };
  return (
    <ActivityRings
      rings={computedMetrics
        .filter((metric) => metric.metric.enabled)
        .map((metric) => generateRingData(metric))}
      options={{
        ringsCenterComponent: hasAntipattern ? (
          <Iconify icon="mdi:alert" color={'rgba(248, 222, 132, 1)'} />
        ) : null,
      }}
    />
  );
}

type Props = {
  metrics: LearningSessionDisplay[];
  hasAntipattern: boolean;
};
