import { Box, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { styled } from '@mui/material/styles';

const TotalTargetBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: '1px solid #F0F0F0',
  borderRadius: '8px',
  marginRight: '6px',
  paddingTop: '4px',
  paddingBottom: '4px',
  paddingRight: '10px',
  paddingLeft: '10px',
}));
const RingTooltipContent = ({
  icon,
  label,
  actualValue,
  targetValue,
  subtitleLabel,
  subtitleBackgroundColor,
}: Props) => (
  <Stack direction={'column'} spacing={'6px'} sx={{ paddingTop: '4px', paddingBottom: '8px' }}>
    <Box display={'flex'} alignItems={'center'}>
      <Box display={'flex'} alignItems={'center'} sx={{ marginRight: '6px' }}>
        {icon}
      </Box>
      <Typography fontSize={'12px'} fontWeight={'400'}>
        {label}
      </Typography>
    </Box>

    <Stack direction={'column'} sx={{ paddingLeft: '15px' }} spacing={'6px'}>
      {targetValue ? (
        <Box display={'flex'} justifyContent={'space-between'}>
          <TotalTargetBox>
            <Typography fontSize={'12px'} fontWeight={'400'} sx={{ marginRight: '8px' }}>
              Total:
            </Typography>
            <Typography fontSize={'12px'} fontWeight={'600'}>
              {actualValue}
            </Typography>
          </TotalTargetBox>
          <TotalTargetBox>
            <Typography fontSize={'12px'} fontWeight={'400'} sx={{ marginRight: '8px' }}>
              Target:
            </Typography>
            <Typography fontSize={'12px'} fontWeight={'600'}>
              {targetValue}
            </Typography>
          </TotalTargetBox>
        </Box>
      ) : null}

      {subtitleLabel && (
        <Box
          display={'flex'}
          alignItems={'center'}
          borderRadius={'6px'}
          width={'180px'}
          sx={{ backgroundColor: subtitleBackgroundColor || 'none', paddingX: '12px' }}
        >
          <Typography fontSize={'12px'} fontWeight={'400'}>
            {subtitleLabel}
          </Typography>
        </Box>
      )}
    </Stack>
  </Stack>
);

type Props = {
  icon: ReactElement;
  label: string;
  actualValue?: string;
  targetValue?: string;
  subtitleLabel?: JSX.Element | string;
  subtitleBackgroundColor?: string;
};

export default RingTooltipContent;
