import { Tooltip } from '@mui/material';
import { ReactElement } from 'react';

const RingTooltip = ({ children, innerElement }: Props) => (
  <Tooltip
    title={innerElement}
    followCursor
    arrow
    placement={'top'}
    componentsProps={{
      arrow: {
        sx: {
          color: 'white',
        },
      },
      tooltip: {
        sx: {
          backgroundColor: 'white',
          color: 'black',
        },
      },
    }}
  >
    {children}
  </Tooltip>
);

type Props = {
  children: ReactElement;
  innerElement: ReactElement;
};

export default RingTooltip;
