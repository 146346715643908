import styles from './InnerActivityRing.module.css';
import React, { ReactElement } from 'react';
import { ActivityRing, ActivityRingContainerOptions } from './types';
import CenteredSVG from '../../CenteredSVG';
import RingTooltip from '../../daily-activity/DailyActivityRing/RingTooltip';
import ConditionalWrapper from '../../ConditionalWrapper';

const InnerActivityRing = ({ index, ring, options }: Props) => {
  const radius =
    options.initialRadius + index * (options.ringWidth + options.paddingBetweenRings) - 2;
  // It's centering logic. That's why it's magic numbers. I don't get it.
  const yTopString = `50% - ${radius + 1}px`;
  const yBottomString = `50% + ${radius - 1}px`;
  const xString = `50% - 2px`;

  const dashArrayPart = Math.min(0.999, ring.filledPercentage) * (2 * radius * Math.PI);

  const hasNonNullValue = !isNaN(ring.filledPercentage);
  return (
    <>
      <circle
        cx="50%"
        cy="50%"
        r={radius}
        stroke={hasNonNullValue ? ring.color : '#C4CDD5'}
        strokeOpacity={hasNonNullValue ? options.backgroundOpacity : 1}
        strokeWidth={options.ringWidth}
        className={styles.innerActivityRingBackground}
      />
      {hasNonNullValue && (
        <ConditionalWrapper
          condition={ring.tooltipInnerElement !== null && ring.tooltipInnerElement !== undefined}
          wrapper={(children: ReactElement) => (
            <RingTooltip innerElement={ring.tooltipInnerElement as ReactElement}>
              {children}
            </RingTooltip>
          )}
        >
          <circle
            cx="50%"
            cy="50%"
            r={radius}
            strokeDasharray={`${dashArrayPart} ${'9'.repeat(index + 4)}`}
            stroke={ring.color}
            strokeWidth={options.ringWidth}
            style={{
              animationDuration: `${options.animationDurationMillis}ms`,
              animationTimingFunction: options.animationTimingFunction,
            }}
            className={styles.innerActivityRing}
          />
        </ConditionalWrapper>
      )}
      <CenteredSVG
        dimensions={{ width: 25, height: 25 }}
        initialYPositionCalcString={`${yTopString}`}
        initialXPositionCalcString={`${xString}`}
      >
        {ring.ringTopComponent}
      </CenteredSVG>
      <CenteredSVG
        dimensions={{ width: 25, height: 25 }}
        initialYPositionCalcString={`${yBottomString}`}
        initialXPositionCalcString={`${xString}`}
      >
        {ring.ringBottomComponent}
      </CenteredSVG>
    </>
  );
};

type Props = {
  // the index of the ring, where 0 is the innermost
  index: number;
  // the ActivityRings properties object
  ring: ActivityRing;
  // the ActivityRingContainer options object
  options: Required<ActivityRingContainerOptions>;
};

export default InnerActivityRing;
