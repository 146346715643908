import React from 'react';
import styles from './Medal.module.css';

type MedalProps = {
  medalType: 'gold' | 'silver' | 'bronze';
  number: number;
};

const Medal = ({ number, medalType = 'gold' }: MedalProps) => (
  <div
    className={`${styles.medal} ${styles[medalType]} ${number > 99 ? styles['small-text'] : ''}`}
  >
    <div className={styles['medal-icon-ribbon-container']}>
      <div className={`${styles['medal-icon']}`}>
        <span>{number}</span>
      </div>
    </div>
  </div>
);

export default Medal;
