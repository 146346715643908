const TextSVG = ({ text, x, y, fontSize, fontWeight, fill }: Props) => (
  <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
    <text x={x} y={y} fontSize={fontSize} fontWeight={fontWeight} fill={fill}>
      {text}
    </text>
  </svg>
);

type Props = {
  text: string;
  x: number;
  y: number;
  fontSize: number;
  fontWeight: number;
  fill: string;
};
export default TextSVG;
