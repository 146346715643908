import React, { useEffect, useRef, useState } from 'react';

const extractDimensions = (el: SVGGraphicsElement) => {
  const bBox = el.getBoundingClientRect();
  return {
    width: bBox.width,
    height: bBox.height,
  };
};
const updateDimensions = (
  ref: React.RefObject<SVGSVGElement>,
  currentDimensions: any,
  setDimensions: (dims: { width: number; height: number }) => void
) => {
  if (ref.current) {
    const dims = extractDimensions(ref.current);
    if (dims.width !== currentDimensions.width || dims.height !== currentDimensions.height) {
      setDimensions(dims);
    }
  }
};

const CenteredSVG = ({
  children,
  initialYPositionCalcString,
  initialXPositionCalcString,
  dimensions,
}: Props) => {
  const svgRef = useRef(null);

  const [svgComponentDimensions, setSvgComponentDimensions] = useState({
    width: dimensions.width,
    height: dimensions.height,
  });

  useEffect(() => {
    updateDimensions(svgRef, svgComponentDimensions, setSvgComponentDimensions);
  }, [svgRef.current]);

  const xPos = `calc(${initialXPositionCalcString} - ${svgComponentDimensions.width / 4}px)`;
  const yPos = `calc(${initialYPositionCalcString} - ${svgComponentDimensions.height / 3}px)`;
  return (
    <svg
      ref={svgRef}
      width={dimensions.width}
      height={dimensions.height}
      viewBox="0 0 30 30"
      y={yPos}
      x={xPos}
    >
      {children}
    </svg>
  );
};

type Props = {
  children: React.ReactNode;
  initialYPositionCalcString?: string;
  initialXPositionCalcString?: string;
  dimensions: { width: number; height: number };
};
export default CenteredSVG;
