import { loadIcons } from '@iconify/react';

type metricDisplayIcons = {
  [key: string]: {
    icon: string;
  };
};

export const metricDisplayConfig: metricDisplayIcons = {
  accuracy: {
    icon: 'mdi:bullseye-arrow',
  },
  minutes: {
    icon: 'mdi:clock',
  },
  qcph: {
    icon: 'mdi:clipboard-check',
  },
  antipattern: {
    icon: 'mdi:alert',
  },
  mastery: {
    icon: 'mdi:star',
  },
};

// Preload all requested icons so that we know their height the first time we load them
const preloadIcons = Object.keys(metricDisplayConfig).map((k) => metricDisplayConfig[k].icon);
loadIcons(preloadIcons, (loaded, missing, pending) => {
  if (loaded.length) {
    // Success, no-op
    return;
  }

  if (pending.length) {
    // Partial success, no-op
    return;
  }

  if (missing.length) {
    // Actual failure
    console.error(`Icons (${missing.join(', ')}) could not be loaded.`);
    return;
  }
});
